@font-face {
  font-family: ImportedComoBold;
  src: url(./Como-Bold/Como-Bold.ttf);
}
:root {
  --firstPagePinkColor: #2697ed;
  --backgroundImage: linear-gradient(to top, rgba(0, 0, 0, 0), #4b4040);
  --pink: #2697ed;
  --themeCustomColor: #2697ed;
  --trnaparentPink: #110f0f50;
  --trnaparentPink1: #110f0f90;
  --trnaparentBlack: rgb(0, 0, 0, 0.3);
  --black: black;
  --white: white;
  --blueLight: #599ff1;
  --gray: rgb(128, 128, 128, 0.5);
  --pinkDark: #232323;
  --pitch: #ff327a;
  --red: red;
  --orange: #fe5625;
  --yellow: #ffcc00;
  --green: #34c759;
  --grayborder: gray;
  --instagramIconColor: white;
  --homepagetitlecolor: white;
  --shoutoutrightcolor: #3700b3;
  --buttonColor: linear-gradient(
    to right,
    var(--themeCustomColor),
    var(--shoutoutrightcolor)
  );
  --lock: linear-gradient(to right, var(--orange), var(--pitch));
  --menubutton: linear-gradient(
    to right,
    var(--black),
    var(--themeCustomColor)
  );
  --pinkTransperent: rgba(181, 153, 212, 0.5);
  --font: ImportedComoBold;
}

body {
  margin: 0px;
}
.general {
  a {
    font-family: var(--font);
  }
  p {
    font-family: var(--font);
  }
  span {
    font-family: var(--font);
  }
  .login-container {
    .MuiTabs-root {
      .Mui-selected {
        background-color: var(--themeCustomColor);
        border: 1px solid var(--black) !important;
      }
    }
    button {
      border-radius: 24px;
      padding-left: 25px;
      padding-right: 25px;
      // border: 1px solid var(--black);
      background-color: transparent;
    }
  }

  .MuiFormControl-root {
    .MuiOutlinedInput-root {
      border-radius: 28px;
      border: 1px solid var(--black);
      background-color: var(--themeCustomColor);
    }
  }

  .MuiTabs-fixed {
    .PrivateTabIndicator-colorSecondary-5 {
      display: none;
    }
  }

  .MuiTabs-flexContainer {
    justify-content: center;
  }

  .MuiTab-wrapper {
    color: var(--white);
  }

  input[placeholder] {
    color: var(--white) !important;
    font-weight: 900;
    font-family: var(--font);
  }

  .MuiTypography-root {
    .MuiButton-containedPrimary {
      background-color: var(--themeCustomColor);
      height: 56px;
      border-radius: 28px;
    }
    color: var(--white);
    .MuiButton-contained {
      color: var(--white) !important;
    }
    .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
      color: var(--white);
    }
    a {
      color: var(--blueLight);
      text-decoration: none;
      span {
        color: var(--white);
      }
    }
  }

  .bgPink {
    background-color: var(--pink);
  }

  .MuiTypography-root {
    .MuiCheckbox-colorSecondary.Mui-checked {
      color: var(--white);
    }
  }

  .loginpage-button:active {
    background-color: var(--pink);
    transform: translateY(4px);
  }
  .typography-loginpage {
    text-transform: none;
    text-shadow: 2px 1px 1px var(--black);
    font-family: var(--font) !important;
  }
  .shadow {
    box-shadow: 0px 0px 7px 1px var(--black);
  }
}

.text-color {
  color: var(--yellow) !important;
}

.text-shadow-typography {
  font-family: var(--font) !important;
  text-shadow: 2px 1px 1px var(--black);
}
.input-field-shadow {
  box-shadow: 0px 0px 10px 0.5px var(--black);
}

.text-align-start {
  text-align: left !important;
}

.text-align-center {
  text-align: center;
}

.rec-slider-container {
  margin: 0px !important;
}

.carousel-item-set {
  .rec-carousel-item-0 {
    margin-left: 8px !important;
  }
}

input[placeholder],
[placeholder],
*[placeholder] {
  color: var(--white) !important;
  font-family: var(--font) !important;
}

.link-color {
  color: var(--blueLight);
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: var(--white) !important;
}

.css-1o6z5ng.Mui-disabled {
  -webkit-text-fill-color: var(--white) !important;
}

.button-text-transform {
  text-transform: none;
}

// .css-vubbuv{
// color: var(--white) !important
// }

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-color {
  background-color: var(--themeCustomColor) !important;
}
