.m_over_home {
  background-color: var(--pink);
  height: 100vh;
  position: fixed;
  padding: 0px 0 0 0;
  width: 100%;

  .m_over_home_tab {
    .tab-over-menu {
      display: flex;
      justify-content: center;
      width: 90%;
      padding: 5px 5%;
      min-height: 35px;

      .MuiTabs-scroller {
        position: relative;
        width: 90%;

        background-color: var(--pinkDark);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 7px 0.1px black;
        .MuiTabs-flexContainer {
          min-width: 100% !important;
          width: 100%;

          padding: 0px !important;
          margin: 0px !important;
          display: grid;
          // justify-content: space-between;
          grid-template-columns: 33.3% 33.3% 33.3%;
          .MuiTab-root {
            min-height: 35px;
            width: 100% !important;
            margin: 0px 1px;
            padding: 0px 0px !important;
            color: var(--yellow);
            font-family: var(--font);
            text-shadow: 0px 0px 0px;
            text-transform: none;
          }
          button {
            width: fit-content;
            border: none !important;
            padding: 0px !important;
          }
          .MuiTab-wrapper {
            width: fit-content !important;
          }
          div {
            img {
              width: 25px;
              aspect-ratio: 1/1;
            }
            span {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;
              color: var(--white);
            }
          }
          .Mui-selected {
            background-color: var(--yellow);
            border-radius: 10px;
            font-family: var(--font);
            font-weight: 500;
            color: var(--white);
            div {
              padding-left: 0px;
              padding-right: 0px;
            }
          }
        }
      }
    }
  }
  .over_panel {
    padding: 0px 0px !important;
    height: 60vh !important;
    width: 100vw;
    overflow: scroll;
    padding-bottom: 10px !important;
    .m_ug_yellow {
      color: var(--yellow);
    }
    .b_update {
      display: grid;
      grid-template-columns: 30% 70%;
      align-items: center;
      margin: 10px 0px;
      .grp_bt {
        height: 30px;
        background-color: var(--pinkDark);
        width: fit-content;
        padding: 1px;
        box-shadow: 0px 0px 7px 1px var(--black);
        .yellow {
          background-color: var(--yellow) !important;
          border-radius: 5px;
        }

        .notyellow {
          background-color: var(--yellow) !important;
          border-radius: 5px;
        }
      }

      #myprofile-form-field {
        background-color: var(--pink);
        border: 2px solid var(--white);
        border-radius: 50px;
        font-family: var(--font);
        height: 25px !important;
        padding: 3px 10px !important;
      }
    }
    .up_b {
      height: 30px !important;
      margin: 10px 25% !important;
    }
    .a {
      padding: 0px !important;
    }
  }
}

// Overview_Current_month

.overview_month {
  text-shadow: 2px 1px 1px var(--black);
  .tc {
    padding: 7px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .pink_bg {
    background-color: var(--pinkDark);
    padding: 5px;
    height: 140px;
    display: flex;
    align-items: center;
  }
  .d_img {
    height: 15px;
    margin-left: 5px;
  }
  .part_b_heading {
    color: var(--yellow);
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .t2 {
    display: flex;
    align-items: center;
    font-size: 12px;
  }
  .part_a {
    display: flex;
    align-items: end;
    padding: 10px 20px;
    .apple_symbol {
      font-size: 40px;
      color: var(--pinkDark) !important;
    }
    .tt {
      padding: 5px;
      display: flex;
      align-items: center;
    }
  }
  .part_b {
    padding: 5px 20px;
    .part_b_heading {
      color: var(--yellow);
      text-align: center;
    }
    .b_text_content {
      padding: 10px 0px;
    }
  }
  .part_c_imgs_box {
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow: scroll;
    padding: 5px 5px;
    .c_box {
      border: 2px solid var(--pink);
      border-radius: 7px;
      height: 120px;
      width: 90px;
      margin: 0px 5px;
    }
    .part_c_img {
      height: 100%;
      width: 90px;
      border-radius: 5px;
    }
    .c_video_box {
      border: 2px solid var(--pink);
      border-radius: 7px;
      height: 120px;
      width: 200px;
      margin: 0px 5px;
      position: relative;
    }
    .part_c_video {
      height: 100%;
      width: 200px;
      border-radius: 5px;
    }
    .video_capton {
      display: flex;
      justify-content: center;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.329);
      width: 100%;
      bottom: 0px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .part_d {
    padding: 0px 0px 10px 0px;
  }
}
