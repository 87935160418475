@media (orientation: portrait) {
  body {
    background-color: var(--black);
  }
  .mainDiv {
    height: fit-content;
  }
  .line1 {
    height: 35px;
    background-image: var(--backgroundImage);
    position: fixed;
    top: 0%;
    left: 0;
    right: 0;
    z-index: 9999;
  }
  .LoginButton {
    color: var(--white) !important;
  }
  .box1 {
    background-color: var(--firstPagePinkColor);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .box2 {
    background-color: var(--black);
    width: 92%;
    height: 32em;
    border-radius: 8px;
    display: flex;
    justify-content: center;
  }
  .image {
    object-fit: cover;

    height: 32em;
    width: 100vw;
    background-color: var(--white);
  }
  .logo {
    color: var(--white);
  }
  .boxLogo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .topright {
    position: fixed;
    top: 8px;
    right: 16px;
  }
  .textDiv {
    background-color: var(--firstPagePinkColor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  .line_11 {
    color: var(--homepagetitlecolor);
    font-family: var(--font);
  }
  .line_1 {
    color: var(--homepagetitlecolor);
  }
  .line_grey {
    color: var(--grayborder);
  }
  .social {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: var(--firstPagePinkColor);
    height: 4em;
  }
  .socialApps {
    background-color: var(--firstPagePinkColor);
    border: 1px solid var(--white);
    width: 100%;
    height: 3.5em;
    border-radius: 12px;
    display: flex;
    align-items: center;
  }
  .css-11vzsv8 {
    border: 2px var(--grayborder);
  }
  .s1 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 63%;
  }
  .rights {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .downloadButtons {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
  .apple {
    text-decoration: none;
    margin: 0;
    padding: 0;
    width: 44%;
    border-radius: 10px;
    background-color: var(--black);
    height: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icons {
    display: flex;
    align-items: center;
  }
  .text1 {
    font-size: 17px;
    text-transform: capitalize;
  }
}

.text-font-family {
  font-family: var(--font);
}
